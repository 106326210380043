import { Container, styled } from "@mui/material";
import { Outlet } from "react-router-dom";
import { InputProvider } from './hooks/useInput';
import Footer from "./components/Footer";
import Header from "./components/Header";
import Socials from "./components/Socials";
import ScrollToTop from "./components/ScrollToTop";

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
`;

function App() {
  return (
    <InputProvider initialValue="0">
      <Wrapper>
        <Header />
        <Container
          maxWidth="xl"
          sx={{ flexGrow: 1, display: "flex", flexDirection: "column", pb: 5 }}
        >
          <Outlet />
        </Container>
        <Socials />
        <Footer />
        <ScrollToTop />

      </Wrapper>
    </InputProvider>
  );
}

export default App;
