import { Button, Typography, styled } from "@mui/material";

const ConnectButtonStyle = styled(Button)`
  border-radius: 6px !important;
  border: 1px solid rgba(255, 255, 255, 0.5);
  height: 24px;
  padding: 0 12px !important;

  .MuiButton-startIcon {
    margin-right: 6px;
    margin-left: 0;
  }
`;

const ConnectButton = ({ img, onConnect }) => {
  return (
    <ConnectButtonStyle
      onClick={onConnect}
      startIcon={<img src={img} alt="" width={16} height={16} />}
    >
      <Typography variant="caption" color="white">
        Connect
      </Typography>
    </ConnectButtonStyle>
  );
};

export default ConnectButton;
