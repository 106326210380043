import { Box, Divider, Skeleton, Typography, styled } from "@mui/material";
import { useMemo } from "react";
import Address from "./Address";
import Coin from "./Coin";
import ConnectButton from "./ConnectButton";
import SelectChain from "./SelectChain";
import { useInput } from '../hooks/useInput';

const FromToBoxStyle = styled("div")`
  background: #00162a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 6px;
  width: 100%;
  max-width: 560px;
`;

const AlertStyle = styled("div")`
  background: linear-gradient(0deg, #ffffff -1.52%, #eaefff 101.52%);
  mix-blend-mode: normal;
  border-radius: 0px 0px 6px 6px;
  padding: 4px 16px;
  display: flex;
  justify-content: center;
`;

const TextEditable = styled(Typography)`
  border: none;
  outline: none;
  margin-right: 12px;
  line-height: 1;
  word-break: break-all;
  text-align: center;
  min-width: 64px;
  text-align: right;
`;

const formatAddress = (address) => {
  if (!address) return "";
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

const formatBalance = (balance) => {
  if (!balance) return "0";
  return parseFloat(parseFloat(balance).toFixed(6));
};

function roundToSixDecimals(str) {
  const num = parseFloat(str);
  if (!isNaN(num) && num > 0.0000000) {
    return Math.ceil(num * 1000000) / 1000000;
  } else {
    return num;
  }
}

function roundToSixDecimalRaw(str) {
  const num = parseFloat(str);
  return Math.round(num * 1000000) / 1000000;
}

const FromToBox = ({
  title,
  chain,
  connected,
  connectIcon,
  onConnect,
  onDisconnect,
  alert,
  setAlert = () => {},
  disabled,
  isLoading,
  address = "",
  balance = 0,
  estimateGas = 0,
  from,
  to,
  setFrom = () => {},
  setTo = () => {},
  inputDisabled = false,
}) => {
  const placeholder = '0'
  const { input, setInput } = useInput();
  const setCursorPosition = (el) => {
    if (el.childNodes.length > 0) {
      const range = document.createRange();
      const selection = window.getSelection();
      range.setStart(el.firstChild, el.textContent.length);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  const handleChange = (e) => {
    const max = roundToSixDecimalRaw(parseFloat(balance - roundToSixDecimals(estimateGas)))
    const inputValue = e.target.textContent;
    const sanitizedInputValue = inputValue.replace(/^0+(?=\d)/, ''); // Remove leading zeros
    const regex = /^(\d*\.)?\d{0,6}$/;
    if (regex.test(sanitizedInputValue) || sanitizedInputValue === "") {
      const parsedValue = parseFloat(sanitizedInputValue);
      if (parsedValue > max) {
        setInput(max.toString());
        e.target.textContent = max.toString();
      } else {
        setInput(sanitizedInputValue);
        e.target.textContent = sanitizedInputValue; // Update the input text content with sanitized value
      }
      setCursorPosition(e.target); // Set the cursor position to the end of the content
    } else {
      e.target.textContent = input; // Reset the input to the previous valid value if the new input doesn't match the regex
      setCursorPosition(e.target); // Set the cursor position to the end of the content
    }
    setAlert(null);
  };

  const handleChangeChain = (e) => {
    if (chain.name !== e.target.value && chain.name === from.name) {
      setFrom(to);
      setTo(from);
      setInput("0");
    }
  };

  const handleFocus = (e) => {
    if (e.target.textContent === placeholder) {
      e.target.textContent = '';
      e.target.classList.remove('content-editable-placeholder');
    }
  };

  const handleBlur = (e) => {
    if (e.target.textContent === '') {
      e.target.textContent = placeholder;
      e.target.classList.add('content-editable-placeholder');
    }
  };

  const image = useMemo(() => chain.img, [chain]);
  const sign = useMemo(() => chain.sign, [chain]);

  return (
    <FromToBoxStyle>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        flexDirection={{ xs: "column", sm: "row" }}
        py={2}
        px={3}
      >
        <Box display="flex" alignItems="center" mb={{ xs: 2, sm: 0 }}>
          <Typography width="64px" flexShrink={0}>
            {title}
          </Typography>
          {!disabled ? (
            <SelectChain onChange={handleChangeChain} value={chain.name} />
          ) : (
            <Box display="flex">
              <Coin token={{ img: image, sign: sign }} size={24} />
              <Typography ml={1.5} variant="body2">
                {chain.name}
              </Typography>
            </Box>
          )}
        </Box>

        {connected ? (
          <Address onDisconnect={onDisconnect} icon={connectIcon} address={formatAddress(address)} />
        ) : (
          <ConnectButton img={connectIcon} onConnect={onConnect} />
        )}
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        px={5}
        pb={3}
      >
        <Box display="flex" alignItems="baseline">
          {disabled ? (
            <Typography
              sx={{ minWidth: "64px" }}
              align="right"
              fontSize="32px"
              mr={1.5}
            >
              {input || '0'}
            </Typography>
          ) : (
            <TextEditable
              role="textbox"
              contentEditable={!disabled && !inputDisabled && !!balance && balance > 0}
              fontSize="32px"
              onInput={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              suppressContentEditableWarning
            >
              0
            </TextEditable>
          )}
          <Typography fontWeight="600" width="64px">
            SIX
          </Typography>
        </Box>
        {!disabled && <Divider flexItem sx={{ my: 1 }} color="#C7CBD5" />}

        <Box mt={0.5} display="flex" justifyContent="center">
          <Typography variant="caption" mr={1}>
            Balance:
          </Typography>
          {isLoading ? (
            <Skeleton
              variant="text"
              sx={{
                fontSize: "1rem",
                width: "80px",
                bgcolor: "rgba(255,255,255,0.2)",
              }}
            />
          ) : (
            <Typography variant="caption">
              {formatBalance(balance)} SIX
            </Typography>
          )}
        </Box>
      </Box>

      {alert && (
        <AlertStyle>
          <Typography variant="caption" color="error" align="center">
            {alert}
          </Typography>
        </AlertStyle>
      )}
    </FromToBoxStyle>
  );
};

export default FromToBox;
