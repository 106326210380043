import { useState, useEffect } from "react";
import { SigningStargateClient } from "@cosmjs/stargate";
import { GasPrice, calculateFee } from "@cosmjs/stargate/build/fee";
import chainConfig from "../chainConfig";
import keplr from "../images/keplr.png";
import FromToBox from "./FromToBox";

const { rpc, rest, chainId } = chainConfig;

const getSigner = async () => {
  const sn = await window.keplr.getOfflineSigner(chainId);

  return sn;
};

const getClientSigner = async () => {
  const sn = await getSigner();
  const cl = await SigningStargateClient.connectWithSigner(rpc, sn);

  return cl;
};

const KeplrFromToBox = ({
  title = "",
  chain,
  setFrom = () => {},
  from,
  to,
  setTo = () => {},
  loading,
  setLoading,
  balance,
  setBalance,
  estimateGas,
  setEstimateGas,
  connected,
  setConnected,
  account,
  setAccount,
  disabled,
  alert,
  setAlert,
  inputDisabled,
  canFetch = false,
  setCanFetch = () => {},
}) => {
  useEffect(() => {
    if (connected && canFetch) {
      getBalance();
    }
  }, [connected, canFetch]);

  const connectWallet = async () => {
    if (!window.keplr) {
      alert("Please install Keplr extension");
      return;
    }
    setLoading(true);
    try {
      await window.keplr.experimentalSuggestChain(chainConfig);

      await window.keplr.enable(chainId);
      const address = await window.keplr.getKey(chainId);
      setAccount(address.bech32Address);
      setConnected(true);
      setAlert(null)
    } catch (error) {
      setLoading(false);
      console.error("Failed to connect wallet:", error);
    }
  };

  const disconnectWallet = () => {
    setLoading(true);
    setConnected(false);
    setBalance(null);
    setAccount(null);
    setLoading(false);
    setEstimateGas(null);
  };

  const getBalance = async () => {
    try {
      const address = await window.keplr.getKey(chainId);

      const sn = await getClientSigner();
      const balance = await sn.getBalance(
        address.bech32Address,
        chainConfig.currencies[0].coinMinimalDenom
      );
      const { amount } = balance;
      const realAmount = amount / 10 ** chainConfig.currencies[0].coinDecimals;
      const estimatedGas = calculateFee(500000, GasPrice.fromString("1.25usix"));
      const realGas = parseFloat(estimatedGas.amount[0].amount) / 10 ** chainConfig.currencies[0].coinDecimals;


      // const apiUrl = rest
      // const wallet = new DirectSecp256k1Wallet();
      // const client = await QueryClient.withExtensions(
      //   { apiUrl },
      //   (signer) => ({}),
      //   wallet
      // );

      // const account = await client.bank.balance(address.bech32Address, "uatom");
      setEstimateGas(realGas);
      setBalance(realAmount);
      setCanFetch(false)
    } catch (error) {
      console.error("Failed to get balance:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FromToBox
      title={title}
      connected={connected}
      connectIcon={keplr}
      onConnect={connectWallet}
      address={account}
      balance={balance}
      chain={chain}
      isLoading={loading}
      setFrom={setFrom}
      from={from}
      to={to}
      setTo={setTo}
      disabled={disabled}
    alert={alert}
    inputDisabled={inputDisabled}
    onDisconnect={disconnectWallet}
    estimateGas={estimateGas}
    />
  );
};

export default KeplrFromToBox;
