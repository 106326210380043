import { Button, styled, Typography } from "@mui/material";

const WhiteGradientButtonStyle = styled(Button)`
  background: linear-gradient(
    180deg,
    #ffffff 60%,
    rgba(255, 255, 255, 0.3) 120%
  );
  padding: 0 24px;
  height: 40px;
  filter: drop-shadow(2px 2px 6px rgba(255, 255, 255, 0.55));

  p {
    font-weight: 800;
    font-size: 1.25rem;
    background: linear-gradient(180deg, #0075ff 0%, #00ffff 128.41%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-transform: uppercase;
  }

  &.Mui-disabled {
    p {
      color: #a8aeb9;
      -webkit-background-clip: initial;
      -webkit-text-fill-color: initial;
      background-clip: initial;
      text-fill-color: initial;
      background: transparent;
    }
  }
`;

const WhiteGradientButton = ({ children, sx, ...props }) => {
  return (
    <WhiteGradientButtonStyle sx={sx} {...props}>
      <Typography>{children}</Typography>
    </WhiteGradientButtonStyle>
  );
};

export default WhiteGradientButton;
