import {
  Box,
  Button,
  Divider,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import discord from "../images/socials/discord.png";
import facebook from "../images/socials/facebook.png";
import kakao from "../images/socials/kakao.png";
import medium from "../images/socials/medium.png";
import telegram from "../images/socials/telegram.png";
import twitter from "../images/socials/twitter.png";

const Socials = ({ isVertical }) => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const list = [
    {
      name: "Twitter",
      img: twitter,
      url: "https://twitter.com/theSIXnetwork",
    },
    {
      name: "Telegram",
      img: telegram,
      url: "https://t.me/SIXnetwork",
    },
    {
      name: "Facebook",
      img: facebook,
      url: "https://web.facebook.com/thesixnetwork",
    },
    {
      name: "Kakaotalk",
      img: kakao,
      url: "https://open.kakao.com/o/gQNRT5K",
    },
    {
      name: "Discord",
      img: discord,
      url: "https://discord.gg/5gJQCXzcWf",
    },

    {
      name: "Medium",
      img: medium,
      url: "https://sixnetwork.medium.com/",
    },
  ];

  return (
    <Box
      className={`${md ? "" : "col-12 justify-center"} d-flex align-center ${
        isVertical ? "flex-row" : "flex-column"
      }`}
      pb={{ xs: 1, sm: 4 }}
    >
      <div
        className={`d-flex p-relative ${
          isVertical ? "flex-column justify-center" : "flex-row justify-center"
        }`}
      >
        {list.map((m) => (
          <Button
            key={`img-${m.name}`}
            component={Link}
            href={m.url}
            target="_blank"
            color="inherit"
            className="d-flex justify-start"
            sx={{
              transition: "0.2s",
              minWidth: "initial",
              p: 0,
              m: 1,
              "&:hover": { filter: "grayscale(1)" },
            }}
          >
            <img src={m.img} alt="" width="40px" />
          </Button>
        ))}
      </div>

      {md && (
        <Box
          className={`d-flex p-relative ${
            isVertical
              ? "flex-column justify-center"
              : "flex-row justify-center"
          }`}
          pl={isVertical ? 2 : 0}
          ml={isVertical ? 2 : 0}
          pt={isVertical ? 0 : 2}
          mt={isVertical ? 0 : 2}
        >
          <Divider
            variant="middle"
            sx={{
              background: "#8D8D8D",
              position: "absolute",
              m: 0,
              top: 0,
              left: 0,
              height: isVertical ? "100%" : "2px",
              width: isVertical ? "2px" : "100%",
            }}
          />

          {list.map((m) => (
            <Button
              key={m.name}
              component={Link}
              href={m.url}
              target="_blank"
              color="inherit"
              className="d-flex justify-start p-relative"
              sx={{
                px: 1.5,
                transition: "0.2s",
                fontWeight: "600",
                "&:before": {
                  opacity: 0,
                  background: "#437FEC",
                  content: '""',
                  position: "absolute",
                  top: isVertical ? 0 : "-17px",
                  left: isVertical ? "-17px" : 0,
                  width: isVertical ? "4px" : "100%",
                  height: isVertical ? "100%" : "4px",
                  transition: "0.2s",
                },
                "&:hover": {
                  color: "#269adc",
                  "&:before": { opacity: 1 },
                },
              }}
            >
              {m.name}
            </Button>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Socials;
