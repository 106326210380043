import { Dialog, Typography } from "@mui/material";
import BoxStyle from "./BoxStyle";
import WhiteGradientButton from "./WhiteGradientButton";

const SuccessBox = ({ open, onClose, message }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { background: "transparent", color: "inherit" } }}
    >
      <BoxStyle
        sx={{ py: "40px !important", width: "400px", maxWidth: "100%" }}
      >
        <span
          className="material-symbols-rounded"
          style={{ fontSize: "64px", color: "#24b272" }}
        >
          task_alt
        </span>
        <Typography variant="h5" mt={1}>
          Successful
        </Typography>
        {message && (
          <Typography align="center" variant="body2">
            {message}
          </Typography>
        )}
        <WhiteGradientButton sx={{ mt: 3 }} onClick={onClose}>
          Done
        </WhiteGradientButton>
      </BoxStyle>
    </Dialog>
  );
};

export default SuccessBox;
