import { Typography, styled } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useState } from "react";
import six from "../images/six.png";
import bg from "../images/selectToken.png";
import Coin from "./Coin";

const SelectStyle = styled(Select)`
  color: inherit;
  width: max-content;
  background: linear-gradient(
    180deg,
    #ffffff 58.73%,
    rgba(255, 255, 255, 0) 116.25%
  );
  border-radius: 6px;
  margin: 6px 0 24px 0;
  position: relative;

  .MuiSelect-select {
    display: flex;
    align-items: center;
    padding: 4px 32px 4px 12px !important;
    width: initial;

    p {
      font-weight: 600;
      color: #555555;
    }
  }

  svg {
    color: #555555;
  }

  fieldset {
    border: none;
    outline: none;
  }

  &:before {
    content: "";
    width: 200%;
    height: 200%;
    background-image: url(${bg});
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }
`;

const SelectToken = ({ value }) => {
  const [currValue, setCurrValue] = useState(value);

  return (
    <SelectStyle
      value={currValue}
      onChange={(e) => {
        setCurrValue(e.target.value);
      }}
    >
      <MenuItem value="SIX">
        <Coin token={{ img: six }} size={24} />
        <Typography ml={1.5}>SIX</Typography>
      </MenuItem>
    </SelectStyle>
  );
};

export default SelectToken;
