import { Box, styled } from "@mui/material";

const BoxStyle = styled(Box)`
  background: linear-gradient(
    111.71deg,
    rgba(202, 223, 244, 0.35) 0%,
    rgba(255, 255, 255, 0.1) 70.85%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  max-width: 640px;
  border-radius: 16px;
  margin: auto;
  width: 100%;
  padding: 24px 16px;
  border: 2px solid rgba(249, 251, 252, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding: 24px;
  }
`;

export default BoxStyle;
