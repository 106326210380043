import { IconButton, Typography } from "@mui/material";
import { useState, useMemo } from "react";
import { useInput } from '../hooks/useInput';
import keplr from "../images/keplr.png";
import metamask from "../images/metamask.png";
import btn from "../images/swap.png";
import BoxStyle from "./BoxStyle";
import SelectToken from "./SelectToken";
import FromToBox from "./FromToBox";
import MetaMaskFromToBox from "./MetamaskFromToBox";
import KeplrFromToBox from "./KeplrFromToBox";
import WhiteGradientButton from "./WhiteGradientButton";

const FormBox = ({
  from,
  setFrom,
  to,
  setTo,
  evmcanFetch,
  evmsetCanFetch,
  evmloading,
  evmsetLoading,
  evmbalance,
  evmsetBalance,
  evmestimateGas,
  evmsetEstimateGas,
  evmconnected,
  evmsetConnected,
  evmaccount,
  evmsetAccount,
  evmweb3,
  evmsetWeb3,
  protocolcanFetch,
  protocolsetCanFetch,
  protocolloading,
  protocolsetLoading,
  protocolbalance,
  protocolsetBalance,
  protocolestimateGas,
  protocolsetEstimateGas,
  protocolconnected,
  protocolsetConnected,
  protocolaccount,
  protocolsetAccount,
  onNext
}) => {
  const { input, setInput } = useInput();
  const [evmError, setEvmError] = useState(null);
  const [protocolError, setProtocolError] = useState(null);

  const fromMetaMask = useMemo(() => {
    return from.name === "SIX Protocol - EVM";
  }, [from]);
  const MetaMaskBox = ({ disabled, inputDisabled }) => (
    <MetaMaskFromToBox
      canFetch={evmcanFetch}
      setCanFetch={evmsetCanFetch}
      title={fromMetaMask ? "From:" : "To:"}
      chain={fromMetaMask ? from : to}
      from={from}
      to={to}
      setTo={setTo}
      setFrom={setFrom}
      input={input}
      setInput={setInput}
      loading={evmloading}
      setLoading={evmsetLoading}
      balance={evmbalance}
      setBalance={evmsetBalance}
      estimateGas={evmestimateGas}
      setEstimateGas={evmsetEstimateGas}
      setBalance={evmsetBalance}
      connected={evmconnected}
      setConnected={evmsetConnected}
      account={evmaccount}
      setAccount={evmsetAccount}
      web3={evmweb3}
      setWeb3={evmsetWeb3}
      disabled={disabled}
      alert={evmError}
      setAlert={setEvmError}
      inputDisabled={inputDisabled}
    />
  );
  const KeplrBox = ({ disabled, inputDisabled }) => (
    <KeplrFromToBox
      canFetch={protocolcanFetch}
      setCanFetch={protocolsetCanFetch}
      title={fromMetaMask ? "To:" : "From:"}
      chain={fromMetaMask ? to : from}
      from={from}
      to={to}
      setTo={setTo}
      setFrom={setFrom}
      input={input}
      setInput={setInput}
      loading={protocolloading}
      setLoading={protocolsetLoading}
      balance={protocolbalance}
      setBalance={protocolsetBalance}
      estimateGas={protocolestimateGas}
      setEstimateGas={protocolsetEstimateGas}
      connected={protocolconnected}
      setConnected={protocolsetConnected}
      account={protocolaccount}
      setAccount={protocolsetAccount}
      disabled={disabled}
      alert={protocolError}
      setAlert={setProtocolError}
      inputDisabled={inputDisabled}
    />
  );

  const topBox = useMemo(() => {
    return fromMetaMask ? <MetaMaskBox inputDisabled={!evmconnected} /> : <KeplrBox inputDisabled={!protocolconnected} />;
  }, [
    fromMetaMask,
    evmcanFetch,
    evmsetCanFetch,
    evmloading,
    evmsetLoading,
    evmbalance,
    evmsetBalance,
    evmestimateGas,
    evmsetEstimateGas,
    evmconnected,
    evmsetConnected,
    evmaccount,
    evmsetAccount,
    evmweb3,
    evmsetWeb3,
    evmError,
    protocolcanFetch,
    protocolsetCanFetch,
    protocolloading,
    protocolsetLoading,
    protocolbalance,
    protocolsetBalance,
    protocolestimateGas,
    protocolsetEstimateGas,
    protocolconnected,
    protocolsetConnected,
    protocolaccount,
    protocolsetAccount,
    protocolError
  ]);

  const bottomBox = useMemo(() => {
    return fromMetaMask ? <KeplrBox disabled /> : <MetaMaskBox disabled />;
  }, [
    fromMetaMask,
    evmcanFetch,
    evmsetCanFetch,
    evmloading,
    evmsetLoading,
    evmbalance,
    evmsetBalance,
    evmestimateGas,
    evmsetEstimateGas,
    evmconnected,
    evmsetConnected,
    evmaccount,
    evmsetAccount,
    evmweb3,
    evmsetWeb3,
    evmError,
    protocolcanFetch,
    protocolsetCanFetch,
    protocolloading,
    protocolsetLoading,
    protocolbalance,
    protocolsetBalance,
    protocolestimateGas,
    protocolsetEstimateGas,
    protocolconnected,
    protocolsetConnected,
    protocolaccount,
    protocolsetAccount,
    protocolError
  ]);

  const handleSwap = () => {
    setFrom(to);
    setTo(from);
    setInput("0");
    setEvmError(null);
    setProtocolError(null);
  };

  const handleNext = () => {
    if (!evmconnected) {
      setEvmError("Please connect your Metamask wallet to continue");
      return false;
    }
    if (!protocolconnected) {
      setProtocolError("Please connect your Keplr wallet to continue");
      return false;
    }
    const num = parseFloat(input);
    if (isNaN(num) || num <= 0) {
      if (fromMetaMask) {
        setEvmError("Amount can not be blanked");
      } else {
        setProtocolError("Amount can not be blanked");
      }
      return false;
    }
    setEvmError(null);
    setProtocolError(null);
    onNext();
  };

  return (
    <BoxStyle>
      <Typography variant="h5" align="center" textTransform="capitalize" mb={1}>
        Token transfer
      </Typography>
      <SelectToken value="SIX" />

      {topBox}

      <IconButton
        sx={{ p: 0, width: "40px", height: "40px", my: 1.5 }}
        onClick={handleSwap}
      >
        <img src={btn} alt="" />
      </IconButton>

      {bottomBox}

      <WhiteGradientButton onClick={handleNext} sx={{ mt: 3 }} disabled={!evmconnected || !protocolconnected} >
        Next
      </WhiteGradientButton>
    </BoxStyle>
  );
};

export default FormBox;
