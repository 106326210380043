import { styled } from "@mui/material";

const CoinStyle = styled("div")`
  position: relative;

  img:nth-of-type(02) {
    position: absolute;
    bottom: -15%;
    right: -15%;
    width: 40%;
  }
`;

const Coin = ({ token, size }) => {
  return (
    <CoinStyle style={{ width: size, height: size }}>
      <img src={token.img} alt="" width={size} height={size} />
      {token.sign && <img src={token.sign} alt="" />}
    </CoinStyle>
  );
};

export default Coin;
