import { AppBar, Container, Link } from "@mui/material";
import logo from "../images/logo.png";

function Header() {
  return (
    <AppBar color="transparent" position="static">
      <Container maxWidth="xl" sx={{ py: 2 }}>
        <Link href="https://sixprotocol.com/">
          <img src={logo} alt="" height="64px" />
        </Link>
      </Container>
    </AppBar>
  );
}

export default Header;
