import { useState } from "react";
import { useInput } from "../hooks/useInput";
import ConfirmBox from "../components/ConfirmBox";
import FormBox from "../components/FormBox";
import evm from "../images/evm.png";
import six from "../images/six.png";

const HomePage = () => {
  const { setInput } = useInput();
  const [evmloading, evmsetLoading] = useState(false);
  const [evmbalance, evmsetBalance] = useState(null);
  const [evmconnected, evmsetConnected] = useState(false);
  const [evmcanFetch, evmsetCanFetch] = useState(true);
  const [evmaccount, evmsetAccount] = useState(null);
  const [evmweb3, evmsetWeb3] = useState(null);
  const [evmestimateGas, evmsetEstimateGas] = useState(null);

  const [protocolloading, protocolsetLoading] = useState(false);
  const [protocolbalance, protocolsetBalance] = useState(null);
  const [protocolconnected, protocolsetConnected] = useState(false);
  const [protocolcanFetch, protocolsetCanFetch] = useState(true);
  const [protocolaccount, protocolsetAccount] = useState(null);
  const [protocolestimateGas, protocolsetEstimateGas] = useState(null);

  const [isInput, setIsInput] = useState(true);
  const [from, setFrom] = useState({
    img: six,
    sign: evm,
    value: "6x0000....0000",
    name: "SIX Protocol - EVM"
  });
  const [to, setTo] = useState({
    img: six,
    value: "6x0000....0000",
    name: "SIX Protocol"
  });

  return isInput ? (
    <FormBox
      evmcanFetch={evmcanFetch}
      evmsetCanFetch={evmsetCanFetch}
      evmloading={evmloading}
      evmsetLoading={evmsetLoading}
      evmbalance={evmbalance}
      evmsetBalance={evmsetBalance}
      evmestimateGas={evmestimateGas}
      evmsetEstimateGas={evmsetEstimateGas}
      evmconnected={evmconnected}
      evmsetConnected={evmsetConnected}
      evmaccount={evmaccount}
      evmsetAccount={evmsetAccount}
      evmweb3={evmweb3}
      evmsetWeb3={evmsetWeb3}
      protocolcanFetch={protocolcanFetch}
      protocolsetCanFetch={protocolsetCanFetch}
      protocolloading={protocolloading}
      protocolsetLoading={protocolsetLoading}
      protocolbalance={protocolbalance}
      protocolsetBalance={protocolsetBalance}
      protocolestimateGas={protocolestimateGas}
      protocolsetEstimateGas={protocolsetEstimateGas}
      protocolconnected={protocolconnected}
      protocolsetConnected={protocolsetConnected}
      protocolaccount={protocolaccount}
      protocolsetAccount={protocolsetAccount}
      from={from}
      setFrom={setFrom}
      to={to}
      setTo={setTo}
      onNext={() => {
        setIsInput(false);
      }}
    />
  ) : (
    <ConfirmBox
      from={from}
      to={to}
      evmaccount={evmaccount}
      protocolaccount={protocolaccount}
      onBack={reset => {
        setIsInput(true);
        if (reset) {
          setInput("0");
          setTimeout(() => {
            evmsetCanFetch(true)
            protocolsetCanFetch(true)
          }, 10000);
          evmsetBalance(null);
          protocolsetBalance(null);
          evmsetLoading(true);
          protocolsetLoading(true);
        }
      }}
    />
  );
};

export default HomePage;
