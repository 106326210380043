const chainConfig = {
  chainId: process.env.REACT_APP_SIX_PROTOCOL_CHAIN_ID,
  chainName: process.env.REACT_APP_SIX_PROTOCOL_CHAIN_NAME,
  rpc: process.env.REACT_APP_SIX_PROTOCOL_RPC_URL,
  rest: process.env.REACT_APP_SIX_PROTOCOL_REST_URL,
  bip44: {
    coinType: 118
  },
  bech32Config: {
    bech32PrefixAccAddr: "6x",
    bech32PrefixAccPub: "6x" + "pub",
    bech32PrefixValAddr: "6x" + "valoper",
    bech32PrefixValPub: "6x" + "valoperpub",
    bech32PrefixConsAddr: "6x" + "valcons",
    bech32PrefixConsPub: "6x" + "valconspub"
  },
  currencies: [
    {
      coinDenom: "SIX",
      coinMinimalDenom: "usix",
      coinDecimals: 6,
      coinGeckoId: "six-network"
    }
  ],
  feeCurrencies: [
    {
      coinDenom: "SIX",
      coinMinimalDenom: "usix",
      coinDecimals: 6,
      coinGeckoId: "six-network"
    }
  ],
  stakeCurrency: {
    coinDenom: "SIX",
    coinMinimalDenom: "usix",
    coinDecimals: 6,
    coinGeckoId: "six-network"
  },
  coinType: 118,
  gasPriceStep: {
    low: 1.25,
    average: 1.45,
    high: 1.65
  },
  features: ["stargate", "ibc-transfer", "no-legacy-stdTx"]
};

export default chainConfig;
