import { useContext, useState } from 'react';
import InputContext from './inputContext';

function useInput() {
  const context = useContext(InputContext);

  if (!context) {
    throw new Error('useInput must be used within a InputProvider');
  }

  return context;
}

function InputProvider({ children, initialValue = null }) {
  const [value, setValue] = useState(initialValue);

  const handleChange = (value) => {
    setValue(value);
  };

  const reset = () => {
    setValue(initialValue);
  };

  return (
    <InputContext.Provider value={{ input: value, setInput: handleChange, reset }}>
      {children}
    </InputContext.Provider>
  );
}

export { useInput, InputProvider };
