import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";

const Address = ({ icon, address, onDisconnect = () => {} }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        sx={{ cursor: "pointer" }}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <Typography
          variant="caption"
          display="flex"
          alignItems="center"
          color="#43E5DD"
        >
          <img
            src={icon}
            alt=""
            width={20}
            height={20}
            style={{ marginRight: "6px" }}
          />
          Connected
          <span
            className="material-symbols-rounded"
            style={{ marginRight: "-8px", marginLeft: "-2px" }}
          >
            arrow_drop_down
          </span>
        </Typography>
        <Typography variant="caption">{address}</Typography>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={onClose}
      >
        <MenuItem
          onClick={() => {
            onDisconnect();
            onClose();
          }}
          dense
          sx={{ color: "error.main" }}
        >
          <ListItemIcon
            sx={{ minWidth: "28px !important", mt: "-2px", color: "inherit" }}
          >
            <span
              className="material-symbols-rounded"
              style={{ fontSize: "20px" }}
            >
              logout
            </span>
          </ListItemIcon>
          <ListItemText>Disconnect</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Address;
