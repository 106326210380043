import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import powerBy from "../images/powerby_six.png";

const Footer = () => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Container maxWidth="xl" component="footer">
      <Box
        className={`d-flex align-center  flex-wrap ${
          sm ? "justify-space-between" : "justify-center"
        }`}
        pb={4}
      >
        <Typography
          variant="caption"
          mb={sm ? 0 : 1.5}
          className="d-flex align-baseline"
          fontSize="12px"
        >
          <Button
            href="/privacy-policy"
            className="pa-0 pr-2"
            color="inherit"
            target="_blank"
            sx={{ fontWeight: "600", fontSize: "inherit" }}
          >
            Privacy Policy
          </Button>
          | © 2022 SIX Network PTE. LTD.
        </Typography>

        <img
          src={powerBy}
          alt=""
          height="24px"
          style={{ marginTop: "-10px" }}
        />
      </Box>
    </Container>
  );
};

export default Footer;
