import { Typography, styled } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useState } from "react";
import evm from "../images/evm.png";
import six from "../images/six.png";
import Coin from "./Coin";

const SelectStyle = styled(Select)`
  color: inherit;
  width: max-content;

  .MuiSelect-select {
    display: flex;
    align-items: center;
    padding: 4px 32px 4px 0 !important;
    width: initial;

    p {
      font-size: 14px;
    }
  }

  svg {
    color: inherit;
  }

  fieldset {
    border: none;
    outline: none;
  }
`;

const SelectChain = ({ value, onChange }) => {
  const [currValue, setCurrValue] = useState(value);

  return (
    <SelectStyle
      value={currValue}
      onChange={onChange}
    >
      <MenuItem value="SIX Protocol - EVM">
        <Coin token={{ img: six, sign: evm }} size={24} />
        <Typography ml={1.5}>SIX Protocol - EVM</Typography>
      </MenuItem>
      <MenuItem value="SIX Protocol">
        <Coin token={{ img: six }} size={24} />
        <Typography ml={1.5}>SIX Protocol</Typography>
      </MenuItem>
    </SelectStyle>
  );
};

export default SelectChain;
